import React, { useEffect, useState } from "react";

function Clips({ heading, date, videos }) {
  return (
    <div className="col-lg-4 margin_clips">
      <div>
        <iframe
          style={{ width: "100%", height: "240px" }}
          src={`https://www.youtube.com/embed/${videos}`}
          title={heading}
          frameBorder="0"
          allowFullScreen
        ></iframe>
        <h2 className="heading-clip">{heading}</h2>
        <h6 className="date">{date}</h6>
      </div>
    </div>
  );
}

export default Clips;

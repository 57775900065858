import React from "react";
import Heading from "../../Components/Heading";

function About() {
  return (
    <section className="about-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="col-lg-5">
              <Heading subtext="Get to Know" mainheading="What We Talk About" />
              <p className="about-paragraph">
                Our podcast delves into the dynamic world of Gen Z, shedding
                light on the unique experiences, challenges, and triumphs of
                today's students who are also budding entrepreneurs. We explore
                the intersection of education, innovation, and social life,
                delving into the journeys of young visionaries who are shaping
                the future.
              </p>
              <p className="about-paragraph margin_about-paragraph">
                Join us as we engage in candid conversations, share inspiring
                stories, and offer valuable insights into the lives of these
                remarkable individuals, offering a fresh perspective on the Gen
                Z experience.
              </p>
            </div>
            <div className="col-lg-7"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;

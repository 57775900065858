import React from "react";
import benaam from "../Assets/Images/benaaam-header.svg";
import framer from "../Assets/Images/header-logo.svg";

function Header() {
  return (
    <header>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="header-absolute">
              <div className="logo-box">
                {/* <img className="logo" src={benaam} alt="logo" loading="lazy" /> */}
                <img className="logo" src={framer} alt="logo" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;

import React from "react";

function HeadingSegment({ subheading, mainheading }) {
  return (
    <div className="margin_segment">
      <h5 className="segment_heading">{subheading}</h5>
      <h1 className="segment_main-heading heavy">{mainheading}</h1>
    </div>
  );
}

export default HeadingSegment;

import React from "react";

function Heading({ subtext, mainheading }) {
  return (
    <>
      <h5 className="about-subtext">{subtext}</h5>
      <h1 className="about_main-heading heavy">{mainheading}</h1>
    </>
  );
}

export default Heading;

import React from "react";
import Heading from "../../Components/Heading";

function Contact() {
  return (
    <section className="contact-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="col-lg-6">
              <Heading
                subtext="Need to talk about anything?"
                mainheading="Contact Us"
              />
              <form action="">
                <div className="row">
                  <div className="col-lg-6">
                    <label htmlFor="username">Name</label>
                    <input type="text" name="username" id="username" />
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" id="email" />
                  </div>
                  <div className="col-lg-12">
                    <label htmlFor="message">Message</label>
                    <textarea name="message" id="message"></textarea>
                    <button className="listening_btn">
                      <span className="listen_text">Send Message</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;

import React from "react";
import HeadingSegment from "../../Components/HeadingSegment";
import youtube from "../../Assets/Images/youtube-color-icon-1.svg";
import SocialBox from "../../Components/SocialBox";
import spotify from "../../Assets/Images/spotify-icon-1.svg";
import apple from "../../Assets/Images/apple-podcasts-icon-1.svg";
import google from "../../Assets/Images/google-podcast-icon-1.svg";

const socialData = [
  {
    img1: youtube,
    heading: "YouTube",
  },
  {
    img1: spotify,
    heading: "Spotify",
  },
  {
    img1: apple,
    heading: "Apple Podcasts",
  },
  {
    img1: google,
    heading: "Google Podcasts",
  },
];

function Socials() {
  return (
    <section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <HeadingSegment
              subheading="Podcasts are available"
              mainheading="Listen Now"
            />
            <div className="row">
              {socialData.map((social, index) => {
                return (
                  <SocialBox
                    key={index}
                    img={social.img1}
                    heading={social.heading}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Socials;

import React, { useEffect, useState } from "react";
import HeadingSegment from "../../Components/HeadingSegment";
import arrow from "../../Assets/Images/iconoir-arrow-tr.svg";
import Clips from "../../Components/Clips";

function Segment() {
  const [showAllClips, setShowAllClips] = useState(false);
  const [showAllHighlights, setShowAllHighlights] = useState(false);
  const [videos, setVideos] = useState([]);
  const apiKey = "AIzaSyBXJxoZt04yU1kSF7VAPpsWX-O9FNniNuo";
  const upload = "UUyoGfZsHBJtvKA9-dD7OmGg";

  const toggleShowAllHighlights = () => {
    setShowAllHighlights(!showAllHighlights);
  };

  const openVideosChannel = () => {
    window.open("https://www.youtube.com/@Benaaamofficial/videos", "_blank");
  };

  const openShortsChannel = () => {
    window.open("https://www.youtube.com/@Benaaamofficial/shorts", "_blank");
  };

  const fetchViews = async (videoId) => {
    const apiUrl = `https://www.googleapis.com/youtube/v3/videos?part=statistics&id=${videoId}&key=${apiKey}`;
    const response = await fetch(apiUrl);
    const data = await response.json();

    if (data.items && data.items.length > 0) {
      return parseInt(data.items[0].statistics.viewCount, 10);
    } else {
      console.error("Video not found or no statistics available.");
      return 0;
    }
  };

  useEffect(() => {
    fetch(
      `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${upload}&key=${apiKey}`
    )
      .then((res) => res.json())
      .then((data) => {
        const videoData = data.items.map((item) => ({
          title: item.snippet.title,
          videoId: item.snippet.resourceId.videoId,
          published: item.snippet.publishedAt,
        }));
        setVideos(videoData);
      });
  }, []);

  useEffect(() => {
    Promise.all(
      videos.map(async (video) => ({
        ...video,
        views: await fetchViews(video.videoId),
      }))
    ).then((videosWithViews) => {
      const sortedVideos = videosWithViews.sort((a, b) => b.views - a.views);
      setVideos(sortedVideos);
    });
  }, [videos]);

  return (
    <section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <HeadingSegment
              subheading="Browse Through"
              mainheading="Latest Podcasts"
            />
            <div className="row">
              {videos
                .filter((video) => video.title.toLowerCase().includes("ep"))
                .slice(0, showAllClips ? videos.length : 6)
                .map((video, index) => (
                  <Clips
                    key={index}
                    heading={video.title}
                    date={video.published}
                    videos={video.videoId}
                  />
                ))}
            </div>
            <div className="justify_center">
              <button
                className="listening_btn"
                onClick={() => {
                  setShowAllClips(!showAllClips);
                  openVideosChannel();
                }}
              >
                <span className="listen_text">
                  {showAllClips ? "Show Less" : "View All"}
                </span>
                <img className="icon-arrow" src={arrow} alt="arrow-icon" />
              </button>
            </div>
          </div>
          <div className="space_gap"></div>
          <div className="col-lg-10 offset-lg-1">
            <HeadingSegment
              subheading="Hot Takes"
              mainheading="Shorts Highlights"
            />
            <div className="row">
              {videos
                .filter((video) => !video.title.toLowerCase().includes("ep"))
                .slice(0, showAllClips ? videos.length : 6)
                .map((video, index) => (
                  <Clips
                    key={index}
                    heading={video.title}
                    date={video.published}
                    videos={video.videoId}
                  />
                ))}
            </div>
            <div className="justify_center">
              <button
                className="listening_btn"
                onClick={toggleShowAllHighlights}
              >
                <span
                  className="listen_text"
                  onClick={() => {
                    setShowAllClips(!showAllClips);
                    openShortsChannel();
                  }}
                >
                  {showAllClips ? "Show Less" : "View All"}
                </span>
                <img className="icon-arrow" src={arrow} alt="arrow-icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Segment;

import React from "react";
import arrow from "../../Assets/Images/iconoir-arrow-tr.svg";
import banner from "../../Assets/Images/banner.webp";

function Landing() {
  return (
    <section className="landing_section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="center column-reverse">
              <div className="col-lg-6">
                <h1 className="landing_main-heading heavy">
                  Podcast for the <br /> Students & Gen-Z.
                </h1>
                <p className="landing_paragraph normal">
                  Navigating the lives of a common Pakistani’s Gen-Z.
                </p>
                <button className="listening_btn ">
                  <span className="listen_text normal">Start Listening</span>{" "}
                  <img
                    className="icon-arrow"
                    src={arrow}
                    alt="iconir-arrow-tr"
                  />
                </button>
              </div>
              <div className="col-lg-6">
                <img src={banner} alt="banner_img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Landing;

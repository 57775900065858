import React from "react";
import benaam from "../Assets/Images/benaam-svg-logo-1.svg";
import framer from "../Assets/Images/logo2.svg";
import spotify from "../Assets/Images/iconoir-spotify.svg";
import youtube from "../Assets/Images/basil-youtube-outline.svg";
import apple from "../Assets/Images/ph-apple-podcasts-logo.svg";
import google from "../Assets/Images/mdi-google-podcast.svg";
import instagram from "../Assets/Images/iconoir-instagram.svg";
import tiktok from "../Assets/Images/iconoir-tiktok.svg";

function Footer() {
  return (
    <footer>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="center margin_to-line  column gap-footer">
              <div className="col-lg-3">
                <div className="logo-box">
                  {/* <img
                    className="logo"
                    src={benaam}
                    alt="logo"
                    loading="lazy"
                  /> */}
                  <img
                    className="logo"
                    src={framer}
                    alt="logo"
                    loading="lazy"
                  />
                </div>
                <div className="social-box">
                  <a href="#" target="__blank">
                    <img
                      className="social-icon"
                      src={spotify}
                      alt="icon-socials"
                      loading="lazy"
                    />
                  </a>
                  <a href="#" target="__blank">
                    <img
                      className="social-icon"
                      src={youtube}
                      alt="icon-socials"
                      loading="lazy"
                    />
                  </a>
                  <a href="#" target="__blank">
                    <img
                      className="social-icon"
                      src={apple}
                      alt="icon-socials"
                      loading="lazy"
                    />
                  </a>
                  <a href="#" target="__blank">
                    <img
                      className="social-icon"
                      src={google}
                      alt="icon-socials"
                      loading="lazy"
                    />
                  </a>
                  <a href="#" target="__blank">
                    <img
                      className="social-icon"
                      src={instagram}
                      alt="icon-socials"
                      loading="lazy"
                    />
                  </a>
                  <a href="#" target="__blank">
                    <img
                      className="social-icon"
                      src={tiktok}
                      alt="icon-socials"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-5"></div>
              <div className="col-lg-4">
                <div className="social-box align_start">
                  <a href="#">
                    <span className="links-heading">Podcasts</span>
                  </a>
                  <a href="#">
                    <span className="links-heading">Clips</span>
                  </a>
                  <a href="#">
                    <span className="links-heading">Our Topics</span>
                  </a>

                  <a href="#">
                    <span className="links-heading">Listen Now</span>
                  </a>
                  <a href="#">
                    <span className="links-heading">Contact Us</span>
                  </a>
                </div>
              </div>
            </div>
            <hr />
            <h6 className="reserve-heading">All Rights Reserved</h6>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

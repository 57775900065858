import React from "react";

function SocialBox({ img, heading }) {
  return (
    <div className="col-lg-3">
      <div className="margin_bottom">
        <div className="container-social">
          <img
            className="social-img"
            src={img}
            alt="social_images"
            loading="lazy"
          />
        </div>
        <h5 className="social-subtext">Available on</h5>
        <h4 className="social-heading">{heading}</h4>
      </div>
    </div>
  );
}

export default SocialBox;

import React, { useEffect } from "react";
import Landing from "./Landing";
import Segment from "./Segment";
import About from "./About";
import Socials from "./Socials";
import Contact from "./Contact";

function Home() {
  useEffect(() => {
    document.title = "Home | Benaaam";
  }, []);
  return (
    <>
      <Landing />
      <Segment />
      <About />
      <Socials />
      <Contact />
    </>
  );
}

export default Home;
